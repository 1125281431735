import React from "react";
import { Link } from "react-router-dom";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from "../common/footer/FooterThree";
import { FiArrowDown } from "react-icons/fi";

const Demo = [
  {
    Url: "/business-consulting",
    Image: "./images/demo/business-consulting.png",
    Imagelight: "./images/demo/business-consulting-light.png",
    Title: "Business Consulting",
    badge: "",
  },

  {
    Url: "/business-consulting-2",
    Image: "./images/demo/business-consulting-2.png",
    Imagelight: "./images/demo/business-consulting-2-light.png",
    Title: "Business Consulting",
    badge: "",
  },
  {
    Url: "/corporate",
    Image: "./images/demo/corporate.png",
    Imagelight: "./images/demo/corporate-light.png",
    Title: "Corporate",
    badge: "",
  },
  {
    Url: "/startup",
    Image: "./images/demo/startup.png",
    Imagelight: "./images/demo/startup-light.png",
    Title: "Startup",
    badge: "New",
  },
  {
    Url: "/web-agency",
    Image: "./images/demo/web-agency.png",
    Imagelight: "./images/demo/web-agency-light.png",
    Title: "Web Agency",
    badge: "New",
  },
  {
    Url: "/international-consulting",
    Image: "./images/demo/international-consulting.png",
    Imagelight: "./images/demo/international-consulting-light.png",
    Title: "International Consulting",
    badge: "New",
  },
  {
    Url: "/consulting",
    Image: "./images/demo/consulting.png",
    Imagelight: "./images/demo/consulting-light.png",
    Title: "Consulting",
    badge: "Hot",
  },
  {
    Url: "/finance",
    Image: "./images/demo/finance.png",
    Imagelight: "./images/demo/finance-light.png",
    Title: "Finance",
    badge: "",
  },
  {
    Url: "/digital-agency",
    Image: "./images/demo/digital-agency.png",
    Imagelight: "./images/demo/digital-agency-light.png",
    Title: "Digital Agency",
    badge: "",
  },
  {
    Url: "/seo-agency",
    Image: "./images/demo/seo-agency.png",
    Imagelight: "./images/demo/seo-agency-light.png",
    Title: "SEO Agency",
    badge: "",
  },

  {
    Url: "/company",
    Image: "./images/demo/company.png",
    Imagelight: "./images/demo/company-light.png",
    Title: "Company",
    badge: "",
  },
  {
    Url: "/personal-portfolio",
    Image: "./images/demo/personal-portfolio.png",
    Imagelight: "./images/demo/personal-portfolio-light.png",
    Title: "Personal Portfolio",
    badge: "",
  },
  {
    Url: "/freelancer",
    Image: "./images/demo/freelancer.png",
    Imagelight: "./images/demo/freelancer-light.png",
    Title: "Freelancer",
    badge: "Hot",
  },
  {
    Url: "/marketing",
    Image: "./images/demo/marketing.png",
    Imagelight: "./images/demo/marketing-light.png",
    Title: "Marketing Agency",
    badge: "",
  },
  {
    Url: "/travel-agency",
    Image: "./images/demo/travel-agency.png",
    Imagelight: "./images/demo/travel-agency-light.png",
    Title: "Travel Agency",
    badge: "",
  },

  {
    Url: "/business",
    Image: "./images/demo/business.png",
    Imagelight: "./images/demo/business-light.png",
    Title: "Business",
    badge: "",
  },

  {
    Url: "/event-conference",
    Image: "./images/demo/event-conference.png",
    Imagelight: "./images/demo/event-conference-light.png",
    Title: "Event Conference",
    badge: "",
  },
  {
    Url: "/creative-portfolio",
    Image: "./images/demo/creative-portfolio.png",
    Imagelight: "./images/demo/creative-portfolio-light.png",
    Title: "Creative Portfolio",
    badge: "",
  },

  {
    Url: "/about-us",
    Image: "./images/demo/corporate-about.png",
    Imagelight: "./images/demo/corporate-about-light.png",
    Title: "Corporate About",
    badge: "",
  },

  {
    Url: "#demo",
    Image: "./images/demo/coming-soon.png",
    Imagelight: "./images/demo/coming-soon.png",
    Title: "Finance Consulting",
    badge: "",
  },

  {
    Url: "#demo",
    Image: "./images/demo/coming-soon.png",
    Imagelight: "./images/demo/coming-soon.png",
    Title: "Finance Consulting",
    badge: "",
  },
];

const InnerPages = [
  {
    id: "1",
    Url: "/blog-grid",
    Image: "./images/demo/blog-grid.png",
    Imagelight: "./images/demo/blog-grid-light.png",
    Title: "Blog Grid",
  },
  {
    id: "2",
    Url: "/blog-grid-sidebar",
    Image: "./images/demo/blog-grid-sidebar.png",
    Imagelight: "./images/demo/blog-grid-sidebar-light.png",
    Title: "Blog Grid Sidebar",
  },
  {
    id: "3",
    Url: "/blog-list-view",
    Image: "./images/demo/blog-list-view.png",
    Imagelight: "./images/demo/blog-list-view-light.png",
    Title: "Blog List View",
  },
  {
    id: "4",
    Url: "/blog-list-sidebar",
    Image: "./images/demo/blog-list-sidebar.png",
    Imagelight: "./images/demo/blog-list-sidebar-light.png",
    Title: "Blog List Sidebar",
  },
  {
    id: "5",
    Url: "/blog-details/3",
    Image: "./images/demo/blog-details.png",
    Imagelight: "./images/demo/blog-details-light.png",
    Title: "Blog Details",
  },
  {
    id: "6",
    Url: "/portfolio",
    Image: "./images/demo/portfolio.png",
    Imagelight: "./images/demo/portfolio-light.png",
    Title: "Portfolio",
  },
  {
    id: "7",
    Url: "/portfolio-three-column",
    Image: "./images/demo/portfolio-three-column.png",
    Imagelight: "./images/demo/portfolio-three-column-light.png",
    Title: "Portfolio Three Column",
  },
  {
    id: "8",
    Url: "/portfolio-full-width",
    Image: "./images/demo/portfolio-full-width.png",
    Imagelight: "./images/demo/portfolio-full-width-light.png",
    Title: "Portfolio Full Width",
  },
  {
    id: "9",
    Url: "/portfolio-grid-layout",
    Image: "./images/demo/portfolio-grid-layout.png",
    Imagelight: "./images/demo/portfolio-grid-layout-light.png",
    Title: "Portfolio Grid Layout",
  },
  {
    id: "10",
    Url: "/portfolio-box-layout",
    Image: "./images/demo/portfolio-box-layout.png",
    Imagelight: "./images/demo/portfolio-box-layout-light.png",
    Title: "Portfolio Box Layout",
  },
  {
    id: "11",
    Url: "/portfolio-details/5",
    Image: "./images/demo/portfolio-details.png",
    Imagelight: "./images/demo/portfolio-details-light.png",
    Title: "Portfolio Details",
  },
  {
    id: "12",
    Url: "/about-us",
    Image: "./images/demo/about-us.png",
    Imagelight: "./images/demo/about-us-light.png",
    Title: "About Us",
  },
  {
    id: "13",
    Url: "/service",
    Image: "./images/demo/service.png",
    Imagelight: "./images/demo/service-light.png",
    Title: "Service",
  },
  {
    id: "14",
    Url: "/team",
    Image: "./images/demo/team.png",
    Imagelight: "./images/demo/team-light.png",
    Title: "Team",
  },
  {
    id: "15",
    Url: "/pricing",
    Image: "./images/demo/pricing.png",
    Imagelight: "./images/demo/pricing-light.png",
    Title: "Pricing",
  },
  {
    id: "16",
    Url: "/testimonial",
    Image: "./images/demo/testimonial.png",
    Imagelight: "./images/demo/testimonial-light.png",
    Title: "Testimonial",
  },
  {
    id: "17",
    Url: "/progressbar",
    Image: "./images/demo/progressbar.png",
    Imagelight: "./images/demo/progressbar-light.png",
    Title: "Progressbar",
  },
  {
    id: "18",
    Url: "/tab",
    Image: "./images/demo/tab.png",
    Imagelight: "./images/demo/tab-light.png",
    Title: "Tab",
  },
  {
    id: "18",
    Url: "/timeline",
    Image: "./images/demo/timeline.png",
    Imagelight: "./images/demo/timeline-light.png",
    Title: "Timeline",
  },
  {
    id: "19",
    Url: "/button",
    Image: "./images/demo/button.png",
    Imagelight: "./images/demo/button-light.png",
    Title: "Button",
  },
  {
    id: "20",
    Url: "/counter",
    Image: "./images/demo/counter.png",
    Imagelight: "./images/demo/counter-light.png",
    Title: "Counter",
  },
  {
    id: "21",
    Url: "/social-share",
    Image: "./images/demo/social-share.png",
    Imagelight: "./images/demo/social-share-light.png",
    Title: "Social Share",
  },
  {
    id: "22",
    Url: "/advance-tab",
    Image: "./images/demo/advance-tab.png",
    Imagelight: "./images/demo/advance-tab-light.png",
    Title: "Advance Tab",
  },
  {
    id: "23",
    Url: "/call-to-action",
    Image: "./images/demo/call-to-action.png",
    Imagelight: "./images/demo/call-to-action-light.png",
    Title: "Call To Action",
  },
  {
    id: "24",
    Url: "/error",
    Image: "./images/demo/error.png",
    Imagelight: "./images/demo/error-light.png",
    Title: "404 Page",
  },
  {
    id: "25",
    Url: "./video-popup",
    Image: "./images/demo/video-popup.png",
    Imagelight: "./images/demo/video-popup-light.png",
    Title: "Video Popup",
  },
  {
    id: "26",
    Url: "./contact",
    Image: "./images/demo/contact.png",
    Imagelight: "./images/demo/contact-light.png",
    Title: "Contact",
  },
];

const Splash = () => {
  return (
    <>
      <SEO title="DeFi Credence" />
      <main className="page-wrapper">
        {/* <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        /> */}

        {/* Start Slider Area  */}
        <div
          className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--700"
          style={{ marginTop: "20%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <div className="react-image mb--20">
                    {/* <img src="./images/demo/react-badge.png" alt="Doob React" /> */}
                  </div>
                  <h1 className="title display-two">
                    DeFi Credence
                    <br />
                  </h1>
                  <p className="description">
                    Building the next generation of DeFi Experience
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-large round btn-icon"
                      href="#demo"
                    >
                      Coming Soon
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
      </main>
    </>
  );
};

export default Splash;
